import { BrandProvider } from './BrandContext';
import BrowserCheck from './BrowserCheck';
import DocumentHead from './components/DocumentHead';
import ErrorBlockedRouter from './components/ErrorBlockedRouter';
import ErrorBoundary from './components/ErrorBoundary';
import FormDataContextProvider from './components/FormDataContext/FormDataContext';
import LandmarkRefsProvider from './components/LandmarkRefsProvider';
import Quotation from './components/Quotation';
import SkipLink from './components/SkipLink';
import TransformedCookieBanner from './components/TransformedCookieBanner';
import AdobeExperimentProvider from './experiments/AdobeExperiment/AdobeExperimentProvider';
import ExperimentProvider from './experiments/ExperimentContext';
import TechnicalError from './pages/TechnicalError';
import ThemeProvider from './ThemeProvider';
import { AggregatorConsumerNameProvider } from './utilities/aggregatorConsumerNameProvider';
import { BRANDS } from './utilities/constants';
import onCookieManagementEvent from './utilities/onCookieManagementEvent';

function App() {
    return (
        <BrowserCheck>
            <BrandProvider>
                <ThemeProvider>
                    <AggregatorConsumerNameProvider>
                        <ErrorBoundary renderOnError={<TechnicalError />}>
                            <LandmarkRefsProvider>
                                <DocumentHead />
                                <SkipLink />
                                <ErrorBlockedRouter>
                                    <ExperimentProvider>
                                        <AdobeExperimentProvider>
                                            <FormDataContextProvider>
                                                <Quotation />
                                                <TransformedCookieBanner
                                                    allowList={BRANDS}
                                                    id="cookie-banner"
                                                    onCookieManagementEvent={onCookieManagementEvent}
                                                />
                                            </FormDataContextProvider>
                                        </AdobeExperimentProvider>
                                    </ExperimentProvider>
                                </ErrorBlockedRouter>
                            </LandmarkRefsProvider>
                        </ErrorBoundary>
                    </AggregatorConsumerNameProvider>
                </ThemeProvider>
            </BrandProvider>
        </BrowserCheck>
    );
}

export default App;
