import { useBrand } from '@cof/plastic-components';

import useQueryParams from '../../utilities/useQueryParams';
import mkExperiment from '../mkExperiment';

const useSimpleHeader = () => {
    const brand = useBrand();
    const { setActive, isInTest } = mkExperiment({
        options: { control: 'M', test: ['N', 'O'] },
        confName: 'experiments.simpleHeader.enabled'
    });
    const { quotation, sParameter, oemc, vendorCode } = useQueryParams();
    setActive({ brand, sParameter, quotation, oemc, vendorCode });
    const { sParameter: sParameterPostSetup } = useQueryParams();
    return {
        isSimpleHeaderTest: isInTest({ brand, sParam: sParameterPostSetup }),
        simpleHeaderTestVariant: sParameterPostSetup?.[1]
    };
};

export default useSimpleHeader;
