import { useBrand } from '@cof/plastic-components';

import useQueryParams from '../../utilities/useQueryParams';
import mkExperiment from '../mkExperiment';

const useContactDetails = () => {
    const brand = useBrand();
    const { setActive, isInTest } = mkExperiment({
        options: { control: 'K', test: 'L' },
        confName: 'experiments.contactDetails.enabled'
    });
    const { quotation, sParameter, oemc, vendorCode } = useQueryParams();
    setActive({ brand, sParameter, quotation, oemc, vendorCode });
    const { sParameter: sParameterPostSetup } = useQueryParams();
    return isInTest({ brand, sParam: sParameterPostSetup });
};

export default useContactDetails;
