/* eslint complexity: ["error", 8] */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Button,
    Flex,
    List,
    Modal,
    ModalCircledIcon,
    PageRow,
    Paragraph,
    ProtectionFinanceIcon,
    StopwatchIcon,
    THEME_CONSTANTS,
    ThumbsUpIcon
} from '@cof/plastic-components';
import { css, styled } from 'styled-components';
import isMobile from 'is-mobile';

import partners from '../../partners';
import { withText } from '../../text/textStore';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer';
import { CelebrationBox, OuterCircle } from './Circle';
import CardImage from '../CardImage';
import { IconlessListItem, ListItem } from './ListItem';
import config from '@cof/ukweb-config/lib/clientConfig';
import { isInstantIssuanceBrand } from '../../utilities/utils';

export const ModalParagraph = (props) => <Paragraph mt="xs" pb="0" {...props} />;
ModalParagraph.displayName = 'ModalParagraph';

const StyledList = styled(List)`
    padding-left: 0;
`;
StyledList.displayName = 'StyledList';

export const StyledModalHeading = styled(Button).attrs(({ brand = partners.DEFAULT }) => ({
    variant: brand === partners.ASOS ? 'secondary' : 'textLinkInline'
}))`
    ${({ theme }) => css`
        text-align: left;
        ${theme.components.link.light}
        ${theme.components.modalHeading}
        ${theme.components.link.medium}
        ${theme.breakpoints.mq.tabletMin} {
            ${theme.components.link.large}
        }
        ${theme.components.StyledModalHeading}
    `}
`;
StyledModalHeading.displayName = 'StyledModalHeading';

export const ModalHeading = ({ brand, children, ...props }) => (
    <StyledModalHeading brand={brand} {...props}>
        {children} <ModalCircledIcon size={[20, null, 25]} verticalAlign="text-bottom" />
    </StyledModalHeading>
);
ModalHeading.propTypes = {
    brand: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export const CircleType = ({ brand }) => {
    if (brand === partners.ASOS) {
        return <CelebrationBox width="30rem" height="30rem" borderRadius="50%" mb="md" />;
    }
    return <OuterCircle data-qa-id="hero-circle" />;
};
CircleType.propTypes = {
    brand: PropTypes.string.isRequired
};

export const HeroListItem = ({ brand, children, ...props }) => {
    if (brand === partners.ASOS) {
        return <IconlessListItem {...props}>{children}</IconlessListItem>;
    }
    return <ListItem {...props}>{children}</ListItem>;
};
HeroListItem.propTypes = {
    brand: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

const getIcon = (key) => {
    switch (key) {
        case 'Stopwatch':
            return StopwatchIcon;
        case 'ProtectionFinance':
            return ProtectionFinanceIcon;
        case 'ThumbsUp':
            return ThumbsUpIcon;
        default:
            return () => React.Fragment;
    }
};

const Hero = ({ Text: text, brand, isEdit = false }) => {
    const isMobileDevice = isMobile({ tablet: true, featureDetect: false });

    const isInstantIssuance =
        config.get('features.instantIssuance') &&
        (config.get('features.instantIssuanceTVG')
            ? isInstantIssuanceBrand(brand) && isMobileDevice && !isEdit
            : brand === partners.ASOS && isMobileDevice);

    const appendInstantIssuancePath = (path) => {
        if (isInstantIssuance) {
            return `InstantIssuanceHero.${path}`;
        }
        return path;
    };

    const [modalIndex, setModalIndex] = useState(-1);

    const onHeroModalOpen = (modalName, index) => {
        pushToDataLayer({
            event: EVENTS.MODAL_OPEN,
            modalName
        });
        setModalIndex(index);
    };
    return (
        <PageRow
            pt={[0, null, 'md']}
            maxWidth={THEME_CONSTANTS.SCREEN_WIDTHS.EIGHT_COLUMN_NO_PADDING}
            position="relative"
        >
            <CircleType brand={brand} />
            <Flex
                position="relative"
                zIndex="5"
                flexWrap="nowrap"
                alignItems="center"
                justifyContent="center"
                data-qa-id="hero"
            >
                <Box width="55%">
                    <StyledList data-qa-id="card-features">
                        <HeroListItem brand={brand} data-qa-id="feature1">
                            <ModalHeading
                                brand={brand}
                                onClick={() =>
                                    onHeroModalOpen(text({ path: appendInstantIssuancePath('feature1') }), 1)
                                }
                                ariaLabel={text({ path: appendInstantIssuancePath('FeatureOne.Header') })}
                            >
                                {text({ path: appendInstantIssuancePath('feature1') })}
                            </ModalHeading>
                            <Modal
                                heading={text({ path: appendInstantIssuancePath('FeatureOne.Header') })}
                                Icon={getIcon(text({ path: appendInstantIssuancePath('FeatureOne.Icon') }))}
                                rootId="root"
                                isOpen={modalIndex === 1}
                                onClose={() => setModalIndex(-1)}
                                id="feature-one"
                                ariaDescribedById="feature-one-description"
                            >
                                <Paragraph id="feature-one-description">
                                    {text({ path: appendInstantIssuancePath('FeatureOne.Text1') })}
                                </Paragraph>
                                {isInstantIssuance && (
                                    <Paragraph id="feature1-paragraph2">
                                        {text({ path: appendInstantIssuancePath('FeatureOne.Text2') })}
                                    </Paragraph>
                                )}
                            </Modal>
                        </HeroListItem>
                        <HeroListItem brand={brand} data-qa-id="feature2">
                            <ModalHeading
                                brand={brand}
                                onClick={() =>
                                    onHeroModalOpen(text({ path: appendInstantIssuancePath('feature2') }), 2)
                                }
                                ariaLabel={text({ path: appendInstantIssuancePath('FeatureTwo.Header') })}
                            >
                                {text({ path: appendInstantIssuancePath('feature2') })}
                            </ModalHeading>
                            <Modal
                                heading={text({ path: appendInstantIssuancePath('FeatureTwo.Header') })}
                                Icon={getIcon(text({ path: appendInstantIssuancePath('FeatureTwo.Icon') }))}
                                rootId="root"
                                isOpen={modalIndex === 2}
                                onClose={() => setModalIndex(-1)}
                                id="feature-two"
                                ariaDescribedById="feature-two-description"
                            >
                                <Paragraph data-qa-id="feature2-paragraph1" id="feature-two-description">
                                    {text({ path: appendInstantIssuancePath('FeatureTwo.Text1') })}
                                </Paragraph>
                                <Paragraph data-qa-id="feature2-paragraph2">
                                    {text({ path: appendInstantIssuancePath('FeatureTwo.Text2') })}
                                </Paragraph>
                            </Modal>
                        </HeroListItem>
                        <HeroListItem brand={brand} data-qa-id="feature3">
                            <ModalHeading
                                brand={brand}
                                onClick={() =>
                                    onHeroModalOpen(text({ path: appendInstantIssuancePath('feature3') }), 3)
                                }
                                ariaLabel={text({ path: appendInstantIssuancePath('FeatureThree.Header') })}
                            >
                                {text({ path: appendInstantIssuancePath('feature3') })}
                            </ModalHeading>
                            <Modal
                                heading={text({ path: appendInstantIssuancePath('FeatureThree.Header') })}
                                Icon={getIcon(text({ path: appendInstantIssuancePath('FeatureThree.Icon') }))}
                                rootId="root"
                                isOpen={modalIndex === 3}
                                onClose={() => setModalIndex(-1)}
                                id="feature-three"
                                ariaDescribedById="feature-three-description"
                            >
                                <Paragraph data-qa-id="feature3-paragraph1" id="feature-three-description">
                                    {text({ path: appendInstantIssuancePath('FeatureThree.Text1') })}
                                </Paragraph>
                                <Paragraph data-qa-id="feature3-paragraph2">
                                    {text({ path: appendInstantIssuancePath('FeatureThree.Text2') })}
                                </Paragraph>
                                {isInstantIssuance && (
                                    <Paragraph data-qa-id="feature3-paragraph3">
                                        {text({ path: appendInstantIssuancePath('FeatureThree.Text3') })}
                                    </Paragraph>
                                )}
                            </Modal>
                        </HeroListItem>
                    </StyledList>
                </Box>
                <Box display={['none', null, 'block']} pl="sm" textAlign="right" flexShrink={0} width="45%">
                    <CardImage brand={brand} alt="" data-qa-id="card-image" />
                </Box>
                <Box display={['block', null, 'none']} pl="sm" textAlign="right" flexShrink={0} width="45%">
                    <CardImage brand={brand} vertical alt="" data-qa-id="card-image" />
                </Box>
            </Flex>
        </PageRow>
    );
};

Hero.propTypes = {
    Text: PropTypes.func.isRequired,
    brand: PropTypes.string,
    isEdit: PropTypes.bool
};

export default withText('Hero')(Hero);
