import { ErrorSummary, THEME_CONSTANTS } from '@cof/plastic-components';

import { useLandmarkRefsContext } from '../LandmarkRefsProvider';

const { COLORS } = THEME_CONSTANTS;

export const onClickError = (reference) => {
    if (typeof reference !== 'undefined' && reference != null) {
        const domElement = document.querySelector(`[id="${reference.current.id}"]`);
        domElement.focus();
        domElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
};

const FormErrors = () => {
    const { fieldErrors, formErrorsRef, submitAttempted = false } = useLandmarkRefsContext();

    const orderedFieldErrors = Object.values(fieldErrors).sort((a, b) =>
        a.reference.current.getBoundingClientRect().top === b.reference.current.getBoundingClientRect().top
            ? a.reference.current.getBoundingClientRect().left - b.reference.current.getBoundingClientRect().left
            : a.reference.current.getBoundingClientRect().top - b.reference.current.getBoundingClientRect().top
    );

    return (
        <ErrorSummary
            backgroundColor={COLORS.global.white}
            ref={formErrorsRef}
            items={submitAttempted ? orderedFieldErrors : []}
            id="qc"
            aria-live="assertive"
            onClickError={onClickError}
        />
    );
};

export default FormErrors;
